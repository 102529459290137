import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import {
  Row,
  Col,
  Card,
  Button,
  Icon,
  UAH,
  SearchSelect,
  Strong,
  InlineSpace,
  FormElement,
  CardTitle,
  Modal,
} from '@smart-kasa/ui';

import { Field } from 'components';
import useModal from 'hooks/useModal';
import { CLASSIFIER_TYPE } from 'constants/classifierType';
import { transformValidationSchema } from 'utils/transformValidationSchema';
import { DiscountType, ReceiptState } from 'services/api/core/receipts/enums';
import type { ReceiptFormData, IReceiptItem } from 'services/api/core/receipts/types';
import { useLazyGetProductsQuery } from 'services/api/core/inventory/api';
import type { IProduct } from 'services/api/core/inventory/types';
import { useShops, ShopSelectField } from 'features/shops';
import { useTerminals, TerminalSelectField } from 'features/terminals';

import calculator from './ReceiptFormCalculator';
import { ReceiptItemList } from './ReceiptItemList';
import { ReceiptFormSchema } from './ReceiptFormSchema';
import { ReceiptItemForm } from '../ReceiptItemForm';
import styles from './ReceiptForm.module.scss';

interface IReceiptForm {
  initialValues?: Partial<ReceiptFormData>;
  onCancel?: () => void;
  onSubmit: (fd: Partial<ReceiptFormData>) => void;
}

export const DISCOUNT_TYPES_MAPPING = [
  {
    key: 'percent',
    value: DiscountType.percent,
    label: '%',
  },
  {
    key: 'currency',
    value: DiscountType.currency,
    label: '₴',
  },
];

export const ReceiptForm = ({ initialValues, onCancel, onSubmit }: IReceiptForm) => {
  const [fetchProducts] = useLazyGetProductsQuery();
  const { shops } = useShops();
  const { terminals } = useTerminals();
  const { isShowing, showModal, hideModal } = useModal();

  const loadProducts = async (term: string) => {
    const { data } = await fetchProducts({ query: term }).unwrap();

    return data;
  };

  const buildReceiptItemFromProduct = (
    form,
    { items = [] }: Partial<ReceiptFormData>,
    product: IProduct
  ) => {
    const { push, update } = form.mutators;
    const index = items && items.findIndex((it) => it.productId === product.id);

    if (index >= 0) {
      const item: IReceiptItem = items[index];

      update('items', index, { ...item, quantity: item.quantity + 1 });
    } else {
      const item: IReceiptItem = {
        name: product.title,
        price: product.price,
        quantity: 1,
        amount: product.price,
        taxGroupId: product.taxGroupId,
        classifierTypeId: product.classifierTypeId,
        classifierCode: product.classifierCode,
        productId: product.id,
        unitTypeId: product.unitTypeId,
        unitTypeName: product.unitTypeName,
        isFreePrice: product.isFreePrice,
      };

      push('items', item);
    }
  };

  const buildReceiptItem = (form, it) => {
    const { push } = form.mutators;

    const item: IReceiptItem = {
      name: it.name,
      price: it.price,
      amount: it.price,
      quantity: it.quantity,
      taxGroupId: it.taxGroupId,
      classifierTypeId: it.classifierTypeId,
      classifierCode: it.classifierCode,
      unitTypeId: it.unitTypeId,
      unitTypeName: it.unitTypeName,
      isProduct: it.isProduct,
    };

    push('items', item);
    hideModal();
  };

  return (
    <Form<Partial<ReceiptFormData>>
      validate={transformValidationSchema(ReceiptFormSchema)}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[calculator]}
      mutators={{ ...arrayMutators }}
    >
      {({ handleSubmit, form, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card>
              <CardTitle>Загальні відомості для чеку</CardTitle>
              <Row gutter={10}>
                <Col span={8}>
                  <Field.Input label="Назва чека" name="title" placeholder="Введіть назву" />
                </Col>
                <Col span={8}>
                  <ShopSelectField
                    name="shopId"
                    label="Торгова точка"
                    placeholder="Оберіть торгову точку"
                    options={shops}
                    onChange={() => form.change('terminalId', undefined)}
                  />
                </Col>
                <Col span={8}>
                  <TerminalSelectField
                    name="terminalId"
                    label="Термінал"
                    placeholder="Оберіть термінал"
                    disabled={!values.shopId}
                    options={terminals.filter(({ shopId }) => shopId === values.shopId)}
                  />
                </Col>
              </Row>
            </Card>

            <InlineSpace direction="vertical" size={20} />

            <Card>
              <CardTitle>Інформація щодо товарів та послуг які підлягають реалізації</CardTitle>
              <Row gutter={10}>
                <Col span={19}>
                  <SearchSelect
                    placeholder="Пошук товарів/послуг"
                    isClearable
                    cacheOptions
                    loadOptions={loadProducts}
                    getOptionLabel={({ title, number, price }) =>
                      `${number} / ${title} - ₴ ${price}`
                    }
                    getOptionValue={({ id }) => id}
                    value={null}
                    onChange={(_value, option) =>
                      buildReceiptItemFromProduct(form, values, option as any)
                    }
                  />
                </Col>
                <Col span={5}>
                  <Button
                    color="primary"
                    width="full"
                    onClick={showModal}
                    rightIcon={<Icon name="add" size="small" />}
                  >
                    Додати товар вручну
                  </Button>
                </Col>
              </Row>

              <FieldArray name="items" component={ReceiptItemList} />

              <Row gutter={10} className={styles.footer}>
                <Col span={6}>
                  <Field.ButtonSet
                    label="Тип знижки:"
                    name="discountTypeId"
                    className={styles.field}
                    options={DISCOUNT_TYPES_MAPPING}
                  />
                </Col>
                <Col span={6}>
                  {values.discountTypeId === DiscountType.currency && (
                    <Field.Number
                      label="Знижка:"
                      name="discountAmount"
                      className={styles.discount}
                      placeholder="0"
                      suffixIcon="₴"
                      allowDecimal={true}
                      allowNegative={true}
                      precision={2}
                    />
                  )}

                  {values.discountTypeId === DiscountType.percent && (
                    <Field.Number
                      label="Знижка:"
                      name="discountPercentage"
                      className={styles.discount}
                      placeholder="0"
                      min={0}
                      max={99.99}
                      allowDecimal={true}
                      allowNegative={false}
                      precision={2}
                      suffixIcon="%"
                    />
                  )}
                </Col>
                <Col span={12} className={styles.total}>
                  <Strong>
                    <span className={styles.totalTitle}>Сума разом:</span>
                    <UAH
                      className={styles.totalAmount}
                      value={values.totalAmount || 0}
                      decreaseHundredths
                    />
                  </Strong>
                </Col>
              </Row>
            </Card>

            <InlineSpace direction="vertical" size={20} />

            <FormElement direction="row" justifyContent="flex-end">
              <Button variant="text" color="primary" onClick={onCancel}>
                Скасувати
              </Button>
              <Button
                width="narrow"
                color="accent"
                type="submit"
                rightIcon={<Icon name="arrowRight" size="small" />}
              >
                Зберегти
              </Button>
            </FormElement>
          </form>

          <Modal show={isShowing} onClose={hideModal} className={styles.itemForm}>
            <CardTitle>Додання позиції до чеку</CardTitle>
            <ReceiptItemForm
              onSubmit={(values) => buildReceiptItem(form, values)}
              onCancel={hideModal}
              initialValues={{
                isProduct: false,
                classifierTypeId: CLASSIFIER_TYPE.NONE,
                quantity: 1,
              }}
            />
          </Modal>
        </>
      )}
    </Form>
  );
};

ReceiptForm.defaultProps = {
  initialValues: {
    state: ReceiptState.pending,
    discountTypeId: DiscountType.percent,
  },
};
