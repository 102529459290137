import { FC, useContext } from 'react';
import { Col, Hint, InlineSpace, Row, UAH } from '@smart-kasa/ui';

import { UnitTypesContext } from 'context/UnitTypesContext';
import { Field } from 'components';
import { TAX_GROUP_MAPPING } from 'constants/taxGroup';
import { CLASSIFIER_TYPE_MAPPING } from 'constants/classifierType';
import type { IReceiptItem } from 'services/api/core/receipts/types';

import { ReceiptItemDelete } from './ReceiptItemDelete';
import styles from './ReceiptForm.module.scss';

interface IReceiptItemRow {
  item: IReceiptItem;
  name: string;
  index: number;
  onRemove: (index: number) => void;
}

export const ReceiptItemRow: FC<IReceiptItemRow> = ({ item, name, index, onRemove }) => {
  const { unitTypes = [] } = useContext(UnitTypesContext);
  const taxGroup = item.taxGroupId ? TAX_GROUP_MAPPING[item.taxGroupId] : null;
  const classifierType = item.classifierTypeId
    ? CLASSIFIER_TYPE_MAPPING[item.classifierTypeId]
    : null;
  const unitType = unitTypes.find((option) => option.code === item.unitTypeId);

  return (
    <Row gutter={10} className={styles.row}>
      <Col span={13} className={styles.info}>
        <ReceiptItemDelete onClick={() => onRemove(index)} />
        <Field.Input className={styles.field} name={`${name}.name`} placeholder="Назва товару" />
        <InlineSpace />
        <Hint margin={2}>
          <div>{taxGroup?.label}</div>
          <div>{classifierType && `${classifierType?.label}: ${item.classifierCode}`}</div>
        </Hint>
      </Col>

      <Col span={5}>
        <Field.Number
          className={styles.field}
          name={`${name}.price`}
          placeholder="Ціна"
          allowDecimal={true}
          allowNegative={false}
          precision={2}
          suffixIcon={<Hint margin={2}>₴ за 1 {unitType && unitType.shortName}</Hint>}
        />
      </Col>

      <Col span={4}>
        <Field.Spinner
          className={styles.field}
          name={`${name}.quantity`}
          min={0}
          precision={3}
          placeholder="Кількість"
        />
      </Col>

      <Col span={2} className={styles.amount}>
        <UAH value={item.amount} />
      </Col>
    </Row>
  );
};
